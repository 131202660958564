export enum ActionTypes {
    SET_IMAGE = 'SET_IMAGE',
    CLEAR_IMAGES = 'CLEAR_IMAGES'
}

// Actions Start
export interface SetImagesAction {
    type: ActionTypes.SET_IMAGE;
    payload: string;
}

export interface ClearImagesAction {
    type: ActionTypes.CLEAR_IMAGES
}
// Actions End

export type Action = SetImagesAction | ClearImagesAction;
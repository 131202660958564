import { Error, StudentClass, Term } from 'utils/constants';
import { Action, ActionTypes, AnnualBroadSheetData, Transcript } from 'actions/scores/types';

export interface Score extends Error {
    ['S/N']?: number;
    _id?: string;
    class: StudentClass;
    firstCA?: number | null;
    secondCA?: number | null;
    thirdCA?: number | null;
    fourthCA?: number | null;
    total?: number;
    exam?: number;
    grade: string;
    remark?: string;
    position?: string;
    term?: Term;
    session?: string;
    subject?: string;
    student?: string;
    createdAt?: Date;
    createdBy?: string;
    lastEditedAt?: Date;
    lastEditedBy?: string;
}

export interface ScoreState {
    annualBroadsheet: AnnualBroadSheetData;
    scores: Score[];
    transcript: Transcript;
    msg: string | null;
}

const initialState: ScoreState = {
    annualBroadsheet: {},
    scores: [],
    msg: null,
    transcript: {},
};

export const scoresReducer =  (state: ScoreState = initialState, action: Action) => {
    let score = {} as Score;
    let scores: Score[];
    let scoreIndex: number;
    switch (action.type) {
        case ActionTypes.ADDED_SCORE:
            return {
                ...state,
                scores: [ action.payload, ...state.scores ]
            };
 
        case ActionTypes.SET_ANNUAL_BROADSHEET_DATA:
            return {
                ...state,
                annualBroadsheet: action.payload
            };
        
        case ActionTypes.SET_SCORES:
            return {
                ...state,
                scores: action.payload
            };

        case ActionTypes.UPDATE_SCORE:
            score = action.payload;
            scores = [...state.scores];
            scoreIndex = scores.findIndex((item: Score) => item._id === score._id);
            scores.splice(scoreIndex, 1, score);

            return {
                ...state,
                score,
                scores
            };

        case ActionTypes.DELETE_SCORE:
            return {
                ...state,
                scores: state.scores.filter(score => score._id !== action.payload)
            };

        case ActionTypes.SET_SCORES_MSG:
            return {
                ...state,
                msg: action.payload
            };

        case ActionTypes.SET_TRANSCRIPT:
            return {
                ...state,
                transcript: action.payload
            }

        default:
            return state;
    }
};
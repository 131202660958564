export const COLORS = {
    primary: '#1e6262',
    black: '#000000',
    borderColor: 'rgb(224, 224, 224)',
    offBlack: '#333333',
    grey: '#969bab',
    darkGrey: '#a9a9a9',
    lightGrey: '#e2e2e2',
    white: '#ffffff',
    offWhite: '#f8f8f8',
    darkTeal: '#091d1d',
    lightTeal: '#f6fafa',
    red: '#EB5757',
    darkRed: '#f21c1c'
};

export const TRANSITION = '0.3s linear all';
export const BOX_SHADOW = '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)';

export const NETWORK_ERROR = 'Network Error';
export const TOKEN_VALUE = 'seminaryAuthToken';

export interface Route {
    text: string;
    link: string;
}

export enum Role {
    ADMIN = 'ADMIN',
    ADMISSION_OFFICER = 'ADMISSION_OFFICER',
    STUDENT = 'STUDENT',
    TEACHER = 'TEACHER',
    FORM_TEACHER = 'FORM_TEACHER',
    RECTOR = 'RECTOR',
}

export type UserRole = `${Role}`;

export enum Classes {
    JS1 = 'JS1',
    JS2 = 'JS2',
    JS3 = 'JS3',
    SS1 = 'SS1',
    SS2 = 'SS2',
    SS3 = 'SS3',
}

export type StudentClass = `${Classes}`;

export enum UserStatus {
    PRESENT = 'PRESENT',
    TRANSFERRED = 'TRANSFERRED',
    GRADUATED = 'GRADUATED',
};

export type UserStatusType = `${UserStatus}`;

export interface ErrorObject<T> {
    errors: T;
    isValid: boolean;
};

export interface Error {
    msg?: string | null;
}

export enum Genders {
    MALE = 'MALE',
    FEMALE = 'FEMALE'
}

export type Gender = `${Genders}`;

export enum ClassCategories {
    JUNIOR_CLASS = 'JUNIOR CLASS',
    SENIOR_CLASS = 'SENIOR CLASS'
}

export type Categories = `${ClassCategories}`;

export enum SubjectCategories {
    GENERAL = 'GENERAL',
    BASIC_SCIENCE = 'BASIC SCIENCE & TECHNOLOGY',
    NIGERIAN_LANGUAGES = 'NIGERIAN LANGUAGES',
    RELIGIUOUS_VALUES = 'RELIGIOUS & NATIONAL VALUES',
    CCA = 'CULTURAL & CREATIVE ARTS',
    PREVOCATION = 'PRE-VOCATIONAL STUDIES',
}

export type SubjectCategory = `${SubjectCategories}`;

export const CLASSES = ['JS1', 'JS2', 'JS3', 'SS1', 'SS2', 'SS3'];

export interface ExamSubject {
    grade: string;
    score: number;
}

export enum EntranceClass {
    JS1 = 'JS1',
    Others = 'OTHERS'
}

export type EntranceClasses = `${EntranceClass}`;

export const REMARKS = [
    'Successful',
    'Not Successful at this time',
];

export enum Terms {
    FIRST_TERM = 'FIRST TERM',
    SECOND_TERM = 'SECOND TERM',
    THIRD_TERM = 'THIRD TERM',
}

export type Term = `${Terms}`;

export interface ScoreExport {
    ['S/N']?: number;
    name: string;
    id: string;
    class: StudentClass;
    firstCA: number | null;
    secondCA: number | null;
    thirdCA: number | null;
    fourthCA?: number | null;
    total: number;
    exam: number | null;
    term?: Term;
    session?: string;
}

export enum ResultType {
    ANNUAL = 'ANNUAL',
    TERMLY = 'TERMLY'
}

export interface PastClass {
    session: string;
    pastClass: StudentClass;
}

export enum Templates {
    ATTENDANCE = 'ATTENDANCE',
    CONDUCT = 'CONDUCT'
}

export const TERMLY_RESULT_COMMENTS = [
    'Average result, work harder next term.',
    'Excellent Performance.',
    'Fair attempt, you may have to sit up next term.',
    'Good result, keep it up.',
    'Poor performance; sit up now!',
    'Weak result, work hard on the subjects you performed poorly.',
    'You cannot afford to fail a core subject.'
];

export const ANNUAL_RESULT_COMMENTS = [
    'Excellent Performance, Promoted.',
    'Average result, work harder next term, Promoted',
    'Good result, keep it up, Promoted.',
    'Fair attempt, you may have to sit up next term, Promoted.',
    // 'Weak result, work hard on the subjects you performed poorly.',
    'You cannot afford to fail a core subject, Promoted on trial.',
    'Poor performance, you are advised to repeat.'
];

export const RECTOR_SIGNATURE = 'https://firebasestorage.googleapis.com/v0/b/seminary-6a595.appspot.com/o/website-images%2Ffr-justin-signature.png?alt=media&token=e59f72bb-29f0-4c7a-a690-e1b8adbb49ae';
export const RECTOR_STAMP = 'https://firebasestorage.googleapis.com/v0/b/seminary-6a595.appspot.com/o/website-images%2Ffr-justin-stamp.png?alt=media&token=ef87d2fe-03c6-490f-9dfa-662fdd11083a';
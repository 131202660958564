import { ChangeEvent, FC, FormEvent, MouseEvent, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Box, 
    Button,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { editArticle, getArticle } from 'actions/articles';
import { AppState } from 'reducers';
import { Error } from 'reducers/errors';
import { ActionTypes as AuthActionTypes, SetErrorsAction } from 'actions/auth/types';

import { COLORS, ErrorObject } from 'utils/constants';

import { validateCreateArticle, ArticleData } from 'utils/validation/articles/create';

import Spinner from 'components/common/Spinner';
import Toast from 'components/common/Toast';
import SuccessModal from 'components/common/SuccessModal';
import TextEditor from 'components/common/TextEditor';
import { ActionTypes, SetArticleMessageAction } from 'actions/articles/types';
import { ARTICLES } from 'routes';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: `${theme.spacing(5)} auto`,
        width: '80%',

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 2),
            width: '100%',
        },

        '& h4': {
            fontWeight: 600,
            textAlign: 'center'
        },

        '& form': {
            backgroundColor: COLORS.offWhite,
            borderRadius: theme.shape.borderRadius,
            marginTop: theme.spacing(3),
            padding: theme.spacing(5),

            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(2)
            },
        }
    },

    image: {
        marginBottom: theme.spacing(1),
        width: '100%'
    }
}));

interface Classes {
    root: string;
    image: string;
}

interface Props {
    handleSetTitle: (title: string) => void;
}

export const EditArticle: FC<Props> = ({ handleSetTitle }: Props): JSX.Element => {
    const classes: Classes = useStyles();
    const dispatch: Dispatch<any> = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const { article, msg } = useSelector((state: AppState) => state.articles);
    const errorsState: Error = useSelector((state: AppState) => state.errors);

    const [title, setTitle] = useState(article.title);
    const [body, setBody] = useState(article.body);

    const [imageSrc, setImageSrc] = useState<string | ArrayBuffer>(article.imageUrl ?? '');
    const [image, setImage] = useState<File>('' as unknown as File);
    
    const [toastTitle, setToastTitle] = useState('ERROR');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ArticleData>({} as ArticleData);

    const fileUploadRef = useRef<any>(null);
    const successModal = useRef<any>(null);
    const toast = useRef<any>(null);

    useEffect(() => {
        handleSetTitle('Edit Article');
        getNewsArticle();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (msg) {
            setLoading(false);
            successModal.current.setText(msg);
            successModal.current.openModal();
        }
    }, [msg]);

    useEffect(() => {
        if (!_.isEmpty(article)) {
            setLoading(false);
        }
    }, [article]);

    // Show error toast when API responds with an error
    useEffect(() => {
        if (!_.isEmpty(errorsState)) {
            setLoading(false);
            setErrors(errorsState as ArticleData);
            setToastTitle('Error');
            toast.current.open();
            dispatch<SetErrorsAction>({
                type: AuthActionTypes.GET_ERRORS,
                payload: {}
            });
        }
    }, [dispatch, errorsState]);

    // Show error toast when there is an error message
    useEffect(() => {
        if (!_.isEmpty(errors)) {
            setLoading(false);
            setErrors(errors as ArticleData);
            setToastTitle('Error');
            toast.current.open();
        }
    }, [dispatch, errors]);

    const getNewsArticle = () => {
        if (_.isEmpty(article)) {
            const id = location.pathname.split('/')[2];
            setLoading(true);
            dispatch(getArticle(id));
        }
    };

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrors({} as ArticleData);

        let data: ArticleData = {
            title,
            body
        };
        
        const { errors, isValid }: ErrorObject<ArticleData> = validateCreateArticle(data);

        if (!isValid) {
            setErrors({ msg: 'Invalid Article Data!', ...errors });
            return;
        }

        setLoading(true);

        if (imageSrc) {
            const post = new FormData();
            post.append('title', title);
            post.append('body', body);
            post.append('image', image);
            dispatch(editArticle(post, article._id!));

        } else {
            dispatch(editArticle(data, article._id!));
        }
    };

    const handleArticleBodyChange = (text: string): void => {
        setBody(text);
    };

    const handleSelectImage = (e: MouseEvent<HTMLElement>) => {
        fileUploadRef.current.click();
    };

    const handleRemoveImage = (): void => {
        setImage('' as unknown as File);
        setImageSrc('');
    };

    const handleSetArticleImage = (e: ChangeEvent<HTMLInputElement>): void => {
        const { files } = e.target;
        setImage(files![0]);
        const reader = new FileReader();

        reader.onload = (() => {
            setImageSrc(reader.result!);
        });
        reader.readAsDataURL(files![0]);
    };

    const dismissAction = () => {
        dispatch<SetArticleMessageAction>({
            type: ActionTypes.SET_ARTICLE_MSG,
            payload: null
        });
        return navigate(ARTICLES);
    };

    return (
        <>            
            <Toast 
                msg={errors.msg || ''}
                duration={6000}
                title={toastTitle}
                type="error"
                variant="standard"
                ref={toast}
            />
            <SuccessModal ref={successModal}dismissAction={dismissAction} />
            {loading && <Spinner />}
            <Box component="section" className={classes.root}>
                <Typography variant="h4">Edit News Article</Typography>
                <Grid container direction="row">
                    <form noValidate onSubmit={onSubmit}>
                        <Grid container direction="row" spacing={4}>
                            <Grid item xs={12}>
                                {imageSrc && 
                                    <img src={imageSrc.toString()} alt="Post" className={classes.image} />
                                }
                                <label htmlFor="raised-button-file">
                                    <Button 
                                        variant="contained" 
                                        color="primary"
                                        onClick={handleSelectImage}
                                    >
                                        Select Image
                                    </Button>
                                </label>
                                {imageSrc && 
                                    <Button 
                                        variant="outlined" 
                                        color="error"
                                        onClick={handleRemoveImage}
                                        style={{ marginLeft: '40px' }}
                                    >
                                        Remove Image
                                    </Button>
                                }
                                <input
                                    ref={fileUploadRef}
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    onChange={handleSetArticleImage}
                                    type="file"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" component="p" sx={{ fontWeight: 700, marginBottom: '5px' }}>Title</Typography>
                                <TextField 
                                    type="text"
                                    value={title}
                                    placeholder="Enter Article Title"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.currentTarget.value.toUpperCase())}
                                    required
                                    helperText={errors.title}
                                    error={errors.title ? true : false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" component="p" sx={{ fontWeight: 700, marginBottom: '5px' }}>Body</Typography>
                                <TextEditor 
                                    value={body} 
                                    onChange={handleArticleBodyChange} 
                                    placeholder="Start typing here . . ."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button color="primary" variant="contained" type="submit" fullWidth size="large">Update Article</Button>
                            </Grid>
                        </Grid>
                    </form>    
                </Grid>
            </Box>    
        </>
    );
};
import { ActionTypes } from '../actions/auth/types';

export interface Error {
    msg?: string;
}

interface Action {
    type: ActionTypes.GET_ERRORS,
    payload: Error | {}
}

const initialState = {};

export const errorsReducer = (state = initialState, action: Action) => {
    switch (action.type) {      
        case ActionTypes.GET_ERRORS:
            return { ...action.payload };

        default:
            return state;
    }
};
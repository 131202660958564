import { StudentClass } from 'utils/constants';
import {  User } from 'reducers/auth';

export interface SubjectTaught {
    _id?: string;
    staffId?: string;
    selectedClass: StudentClass;
    subject: string;
}

export enum ActionTypes {
    SET_STAFFS = 'SET_STAFFS',
    SET_STAFF = 'SET_STAFF',
    UPDATE_STAFF = 'UPDATE_STAFF',
    SET_STAFF_SUBJECT = 'SET_STAFF_SUBJECT',
    UNSET_STAFF_SUBJECT = 'UNSET_STAFF_SUBJECT',
    SET_STAFF_MSG = 'SET_STAFF_MSG',
}

// Actions Start
export interface SetStaffsAction {
    type: ActionTypes.SET_STAFFS;
    payload: User[] | [];
}

export interface SetStaffAction {
    type: ActionTypes.SET_STAFF;
    payload: User
}

export interface SetStaffMessageAction {
    type: ActionTypes.SET_STAFF_MSG;
    payload: string | null;
}

export interface UpdateStaffAction {
    type: ActionTypes.UPDATE_STAFF;
    payload: User;
}

interface SetStaffSubjectActionPayload {
    subjects: SubjectTaught[];
    staffId: string;
}

export interface UnsetStaffSubjectActionPayload {
    _id: string;
    staffId: string;
}

export interface SetStaffSubjectAction {
    type: ActionTypes.SET_STAFF_SUBJECT;
    payload: SetStaffSubjectActionPayload;
}

export interface UnsetStaffSubjectAction {
    type: ActionTypes.UNSET_STAFF_SUBJECT;
    payload: UnsetStaffSubjectActionPayload;
}
// Actions End

export type Action = SetStaffsAction | SetStaffAction | UpdateStaffAction | SetStaffSubjectAction | UnsetStaffSubjectAction | SetStaffMessageAction;
import { Dispatch } from 'redux';
import { batch } from 'react-redux';
import { ActionTypes, DeletedArticleAction, EditedArticle, SetArticleAction, SetArticleMessageAction, SetArticlesAction } from './types';
import axios, { AxiosError } from 'axios';

import handleError from 'utils/handleError';

import { ArticleData } from 'utils/validation/articles/create';
import toast from 'react-hot-toast';

const API = '/api/articles';
let err;

export interface ChangePasswordPayload {
    msg?: string;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
};

export const createArticle = (data: ArticleData | FormData) => async (dispatch: Dispatch) => {
    try {
        const res = await axios.post(API, data);
        console.log(res);
        batch(() => {
            dispatch<SetArticleAction>({
                type: ActionTypes.SET_ARTICLE,
                payload: res.data.data
            });
            dispatch<SetArticleMessageAction>({
                type: ActionTypes.SET_ARTICLE_MSG,
                payload: res.data.msg
            });
        });
    } catch (error) {
        err = error as AxiosError;
        return handleError(err, dispatch);
    }
};

export const editArticle = (data: ArticleData | FormData, id: string) => async (dispatch: Dispatch) => {
    try {
        const res = await axios.patch(`${API}/${id}`, data);
        batch(() => {
            dispatch<EditedArticle>({
                type: ActionTypes.EDITED_ARTICLE,
                payload: res.data.data
            });
            dispatch<SetArticleMessageAction>({
                type: ActionTypes.SET_ARTICLE_MSG,
                payload: res.data.msg
            });
        });
    } catch (error) {
        err = error as AxiosError;
        return handleError(err, dispatch);
    }
};

export const getArticle = (id: string) => async (dispatch: Dispatch) => {
    try {
        const res = await axios.get(`${API}/${id}`);
        dispatch<SetArticleAction>({
            type: ActionTypes.SET_ARTICLE,
            payload: res.data.data
        });
    } catch (error) {
        err = error as AxiosError;
        return handleError(err, dispatch);
    }
};

export const getArticles = () => async (dispatch: Dispatch) => {
    try {
        const res = await axios.get(API);
        dispatch<SetArticlesAction>({
            type: ActionTypes.SET_ARTICLES,
            payload: res.data.data
        });
    } catch (error) {
        err = error as AxiosError;
        return handleError(err, dispatch);
    }
};

export const deleteArticle = (id: string) => async (dispatch: Dispatch) => {
    try {
        const res = await axios.delete(`${API}/${id}`);
        dispatch<DeletedArticleAction>({
            type: ActionTypes.DELETED_ARTICLE,
            payload: id
        });
        return toast.success(res.data.msg);
    } catch (error) {
        err = error as AxiosError;
        return handleError(err, dispatch);
    }
};
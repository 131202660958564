import Validator from 'validator';
import isEmpty from 'utils/isEmpty';
import { ErrorObject } from 'utils/constants';
import { Error } from 'reducers/errors';

export interface ArticleData extends Error {
    _id?: string;

    title: string

    body: string;

    imageUrl?: string;

    filename?: string;

    slug?: string;

    date?: string,

    createdBy?: string;

    createdAt?: Date

    updatedAt?: Date

    lastUpdatedBy?: string;
}

export const validateCreateArticle = (data: ArticleData): ErrorObject<ArticleData> => {
    let errors = {} as ArticleData;

    data.body = !isEmpty(data.body) ?  data.body : '';
    data.title = !isEmpty(data.title) ?  data.title : '';

    if (Validator.isEmpty(data.title)) {
        errors.title = 'Article title is required!';
    }

    if (Validator.isEmpty(data.body)) {
        errors.body = 'Article body is required!';
    }
    
    return {
        errors,
        isValid: isEmpty(errors)
    } as ErrorObject<ArticleData>;
};
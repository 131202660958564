import { Preference } from 'reducers/preference';

export enum ActionTypes {
    SET_PREFERENCE = 'SET_PREFERENCE',
    SET_PROSPECTUS = 'SET_PROSPECTUS',
    SET_ADMISSION_LETTER = 'SET_ADMISSION_LETTER',
    SET_PREFERENCE_MSG = 'SET_PREFERENCE_MSG',
}

// Actions Start
export interface SetPreferenceAction {
    type: ActionTypes.SET_PREFERENCE;
    payload: Preference
}

export interface SetPreferenceMessageAction {
    type: ActionTypes.SET_PREFERENCE_MSG;
    payload: string | null;
}

export interface SetProspectusAction {
    type: ActionTypes.SET_PROSPECTUS;
    payload: string
}

export interface SetAdmissionLetterAction {
    type: ActionTypes.SET_ADMISSION_LETTER;
    payload: string
}
// Actions End

export type Action = SetPreferenceAction | SetPreferenceMessageAction | SetProspectusAction | SetAdmissionLetterAction;
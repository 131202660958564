import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { AppState } from 'reducers';
import { LOGIN } from 'routes';

interface Props {
    children: JSX.Element;
}

const PrivateRoute: FC<Props> = ({ children }: Props): JSX.Element => {
    const location = useLocation();
    const { isAuthenticated } = useSelector((state: AppState) => state.auth);
    return isAuthenticated ? children : <Navigate to={LOGIN} state={{ from: location }} replace />
};

export default PrivateRoute; 
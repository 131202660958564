import { Action, ActionTypes, Pin } from 'actions/pins/types';

export interface PinState {
    pins: Pin[];
    msg: string | null;
}

const initialState: PinState = {
    pins: [],
    msg: null
};

export const pinsReducer =  (state: PinState = initialState, action: Action) => {

    switch (action.type) {
        case ActionTypes.SET_PINS:
            return {
                ...state,
                pins: action.payload
            };

        case ActionTypes.SET_PIN_MSG:
            return {
                ...state,
                msg: action.payload
            };

        default:
            return state;
    }
};
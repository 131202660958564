import _ from 'lodash';
import { EntranceResult } from 'actions/entranceResults/types';
import { Action, ActionTypes } from 'actions/entranceResults/types';

export interface EntranceResultState {
    result: EntranceResult;
    results: EntranceResult[];
    msg: string | null;
}

const initialState: EntranceResultState = {
    result: {} as EntranceResult,
    results: [],
    msg: null,
};

export const entranceResultReducer =  (state: EntranceResultState = initialState, action: Action) => {
    let result = {} as EntranceResult;
    let results: EntranceResult[];
    let resultIndex: number;

    switch (action.type) {
        case ActionTypes.SET_ENTRANCE_RESULT:
            return {
                ...state,
                result: action.payload
            };

        case ActionTypes.SET_ENTRANCE_RESULTS:
            return {
                ...state,
                results: action.payload
            };

        case ActionTypes.UPDATE_ENTRANCE_RESULT:
            result = action.payload;
            results = [...state.results];
            resultIndex = results.findIndex((item: EntranceResult) => item._id === result._id);
            results.splice(resultIndex, 1, result);

            return {
                ...state,
                result: _.isEmpty(result) ? {} as EntranceResult : result,
                results
            };

        case ActionTypes.SET_ENTRANCE_RESULT_MSG:
            return {
                ...state,
                msg: action.payload
            };

        default:
            return state;
    }
};
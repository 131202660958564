import { Dispatch } from 'redux';
import { ActionTypes, SetErrorsAction } from 'actions/auth/types';
import { NETWORK_ERROR } from './constants';

const handleError =  (err: any, dispatch: Dispatch) => {
    console.error(err);
    console.log(err?.response?.errors);
    
    if (err?.message === NETWORK_ERROR) {
        return dispatch({
            type: ActionTypes.GET_ERRORS,
            payload: { msg: NETWORK_ERROR }
        });     
    }
    
    console.log(err.response);
    const { errors } = err?.response?.data;
    return dispatch<SetErrorsAction>({
        type: ActionTypes.GET_ERRORS,
        payload: { ...errors }
    });
};

export default handleError;
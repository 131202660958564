import { FC, Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { persistor } from './store';
import { TOKEN_VALUE } from 'utils/constants';
import setAuthToken from 'utils/setAuthToken';

import PrivateRoute from 'components/common/PrivateRoute';
import Spinner from 'components/common/Spinner';

import { getCurrentUser } from 'actions/auth';

import {
	DASHBOARD,
	PREFERENCES,
	LOGIN,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	VIEW_STAFF,
	STAFF,
	ADD_STUDENT,
	PROMOTE_STUDENTS,
	ADD_SUBJECT,
	MANAGE_SUBJECTS,
	SUBJECTS_ANALYSIS,
	SUBJECTS_ANALYSIS_CHART,
	ADD_ENTRANCE_RESULT,
	EDIT_ENTRANCE_RESULT,
	PROSPECTUS,
	ADMISSION_LETTER,
	VIEW_ADMISSION_LETTER,
	VIEW_PROSPECTUS,
	RESULT_PINS,ADD_STAFF,
	ENTRANCE_RESULT,
	ENTRANCE_RESULTS,
	RESULT_CHECKER,
	STUDENTS,
	VIEW_STUDENT,
	SCORES,
	ANNUAL_RESULTS,
	ANNUAL_CLASS_RESULTS,
	JUNIOR_STUDENT_RESULT,
	SENIOR_STUDENT_RESULT,
	TERMLY_RESULTS,
	TERMLY_CLASS_RESULTS,
	RESULTS,
	RATING_TEMPLATES,
	ANNUAL_RESULT_COMMENTS,
	TERMLY_RESULT_COMMENTS,
	TERMLY_BROAD_SHEET,
	ANNUAL_PERFORMANCE_ANALYSIS,
	TERMLY_PERFORMANCE_ANALYSIS,
	PROFILE,
	ADD_BROADSHEET_DATA,
	TRANSCRIPTS,
	ABOUT,
	NEWS,
	GALLERY,
	CONTACT,
	ARTICLES,
	CREATE_ARTICLE,
	EDIT_ARTICLE,
	ANNUAL_BROAD_SHEET
} from 'routes';
import { EditArticle } from 'pages/dashboard/articles/EditArticle';

const Home = lazy(() => import('./pages/home'));
const About = lazy(() => import('./pages/about'));
const News = lazy(() => import('./pages/news'));
const NewsArticle = lazy(() => import('./pages/news/NewsArticle'));
const Gallery = lazy(() => import('./pages/gallery'));
const Contact = lazy(() => import('./pages/contact'));

const Login = lazy(() => import('./pages/auth/Login').then(module => ({ default: module.Login })));
const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword').then(module => ({ default: module.ForgotPassword })));
const ResetPassword = lazy(() => import('pages/auth/ResetPassword').then(module => ({ default: module.ResetPassword })));

const ResultChecker = lazy(() => import('./pages/entranceResult').then(module => ({ default: module.ResultChecker })));
const EntranceResult = lazy(() => import('./pages/entranceResult/EntranceResult').then(module => ({ default: module.EntranceResult })));
const ViewAdmissionLetter = lazy(() => import('./pages/entranceResult/AdmissionLetter').then(module => ({ default: module.AdmissionLetter })));
const ViewProspectus = lazy(() => import('./pages/entranceResult/Prospectus').then(module => ({ default: module.Prospectus })));

const CreateArticle = lazy(() => import('./pages/dashboard/articles/CreateArticle').then(module => ({ default: module.CreateArticle })));
const Articles = lazy(() => import('./pages/dashboard/articles/Articles').then(module => ({ default: module.Articles })));


const DashboardHome = lazy(() => import('pages/dashboard/Home'));
const Preferences = lazy(() => import('pages/dashboard/preferences').then(module => ({ default: module.Preferences })));
const Profile = lazy(() => import('pages/dashboard/account').then(module => ({ default: module.Profile })));

const EntranceResults = lazy(() => import('pages/dashboard/admissions/EntranceResults').then(module => ({ default: module.EntranceResults })));
const AddEntranceResult = lazy(() => import('pages/dashboard/admissions/AddEntranceResult').then(module => ({ default: module.AddEntranceResult })));
const EditEntranceResult = lazy(() => import('pages/dashboard/admissions/EditEntranceResult').then(module => ({ default: module.EditEntranceResult })));
const AdmissionLetter = lazy(() => import('pages/dashboard/admissions/AdmissionLetter').then(module => ({ default: module.AdmissionLetter })));
const Prospectus = lazy(() => import('pages/dashboard/admissions/Prospectus').then(module => ({ default: module.Prospectus })));
const ResultPins = lazy(() => import('pages/dashboard/admissions/Pins').then(module => ({ default: module.ResultPins })));

const AddStaff = lazy(() => import('pages/dashboard/staff/AddStaff').then(module => ({ default: module.AddStaff })));
const Staff = lazy(() => import('pages/dashboard/staff/Staff').then(module => ({ default: module.Staff })));
const ViewStaff = lazy(() => import('pages/dashboard/staff/ViewStaff').then(module => ({ default: module.ViewStaff })));

const AddSubject = lazy(() => import('pages/dashboard/subjects/AddSubject').then(module => ({ default: module.AddSubject })));
const Subjects = lazy(() => import('pages/dashboard/subjects/Subjects').then(module => ({ default: module.Subjects })));
const EditSubject = lazy(() => import('pages/dashboard/subjects/EditSubject').then(module => ({ default: module.EditSubject })));
const SubjectsAnalysis = lazy(() => import('pages/dashboard/subjects/SubjectsAnalysis').then(module => ({ default: module.SubjectsAnalysis })));
const SubjectAnalysisChart = lazy(() => import('pages/dashboard/subjects/SubjectAnalysisChart').then(module => ({ default: module.SubjectAnalysisChart })));

const AddStudent = lazy(() => import('pages/dashboard/students/AddStudent').then(module => ({ default: module.AddStudent })));
const Student = lazy(() => import('pages/dashboard/students/Student').then(module => ({ default: module.Student })));
const Students = lazy(() => import('pages/dashboard/students/Students').then(module => ({ default: module.Students })));
const PromoteStudents = lazy(() => import('pages/dashboard/students/PromoteStudents').then(module => ({ default: module.PromoteStudents })));
const TermlyPerformanceAnalysis = lazy(() => import('pages/dashboard/students/TermlyPerformanceAnalysis').then(module => ({ default: module.TermlyPerformanceAnalysis })));
const AnnualPerformanceAnalysis = lazy(() => import('pages/dashboard/students/AnnualPerformanceAnalysis').then(module => ({ default: module.AnnualPerformanceAnalysis })));

const Scores = lazy(() => import('pages/dashboard/scores/Scores').then(module => ({ default: module.Scores })));
const AnnualBroadSheet = lazy(() => import('pages/dashboard/scores/AnnualBroadSheet').then(module => ({ default: module.AnnualBroadSheet })));
const TermlyBroadSheet = lazy(() => import('pages/dashboard/scores/TermlyBroadSheet').then(module => ({ default: module.TermlyBroadSheet })));

const AnnualClassResults = lazy(() => import('pages/dashboard/results/AnnualClassResults').then(module => ({ default: module.AnnualClassResults })));
const ViewAnnualResults = lazy(() => import('pages/dashboard/results/ViewAnnualResults').then(module => ({ default: module.ViewAnnualResults })));
const TermlyClassResults = lazy(() => import('pages/dashboard/results/TermlyClassResults').then(module => ({ default: module.TermlyClassResults })));
const ViewStudentResults = lazy(() => import('pages/dashboard/results/ViewStudentResults').then(module => ({ default: module.ViewStudentResults })));
const ViewTermlyResults = lazy(() => import('pages/dashboard/results/ViewTermlyResults').then(module => ({ default: module.ViewTermlyResults })));
const JuniorStudentResult = lazy(() => import('pages/dashboard/results/JuniorStudentResult').then(module => ({ default: module.JuniorStudentResult })));
const SeniorStudentResult = lazy(() => import('pages/dashboard/results/SeniorStudentResult').then(module => ({ default: module.SeniorStudentResult })));
const RatingTemplates = lazy(() => import('pages/dashboard/results/RatingTemplates').then(module => ({ default: module.RatingTemplates })));
const AnnualResultComments = lazy(() => import('pages/dashboard/results/AnnualResultComments').then(module => ({ default: module.AnnualResultComments })));
const TermlyResultComments = lazy(() => import('pages/dashboard/results/TermlyResultComments').then(module => ({ default: module.TermlyResultComments })));

const Transcripts = lazy(() => import('pages/dashboard/transcripts').then(module => ({ default: module.Transcripts })));
const Transcript = lazy(() => import('pages/dashboard/transcripts/Transcript').then(module => ({ default: module.Transcript })));

const AddBroadsheetData = lazy(() => import('pages/dashboard/broadsheet').then(module => ({ default: module.AddBroadsheetData })));

const Dashboard = lazy(() => import('pages/dashboard'));

const theme = createTheme({
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true,
				disableRipple: true,
				disableFocusRipple: true,
				disableTouchRipple: true
			},
			styleOverrides: {
				root: {
					
				}
			}
		}
	},
	// overrides: {
	// 	MuiButton: {
	// 	  	root: {
	// 			borderRadius: '5px',
	// 			boxShadow: 'none !important',
	// 			paddingBottom: '14px',
	// 			paddingTop: '14px',
	// 			textTransform: 'capitalize'
	// 	  	},
	// 	},
	// },
	
	palette: {
		primary: {
			// light: '#338080',
			main: '#002347',
			// dark: '#990300'
		},

		secondary: {
			main: '#333333'
		},

		text: {
			// primary: '#f8f8f8',
			// secondary: '#f8f8f8'
		}
	},

	breakpoints: {
		values: {
			xs: 0,
			sm: 480,
			md: 769,
			lg: 1024,
			xl: 1920
		}
	},

	typography: {
		fontFamily: "'Quicksand', sans-serif",
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		// fontWeightBold: 600,
		fontWeightBold: 700,
	}
});

const App: FC = (): JSX.Element => {
	const dispatch = useDispatch();

	const [title, setTitle] = useState<string>('');

	useEffect(() => {
		if (localStorage.getItem(TOKEN_VALUE)) {
			// Set auth token header auth
			setAuthToken(localStorage.getItem(TOKEN_VALUE)!);
			// Get current user
			dispatch(getCurrentUser());
		}

		// eslint-disable-next-line
	}, []);

	const handleSetTitle = (title: string): void => setTitle(title);

	return (
		<PersistGate loading={null} persistor={persistor}>
			<ThemeProvider theme={theme}>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<CssBaseline />
					<Suspense fallback={<Spinner />}>
						<Routes>
							<Route path="/" element={<Home />} />
							{/* <Route path="/" element={<></>} /> */}
							<Route path={ABOUT} element={<About />} />
							<Route path={NEWS} element={<News />} />
							<Route path={`${NEWS}/:id`} element={<NewsArticle />} />
							<Route path={GALLERY} element={<Gallery />} />
							<Route path={CONTACT} element={<Contact />} />

							<Route path={RESULT_CHECKER} element={<ResultChecker />} />
							<Route path={ENTRANCE_RESULT} element={<EntranceResult />} />
							<Route path={`${VIEW_ADMISSION_LETTER}/:id`} element={<ViewAdmissionLetter />} />
							<Route path={`${VIEW_PROSPECTUS}/:id`} element={<ViewProspectus />} />
							<Route path={LOGIN} element={<Login />} />
							<Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
							<Route path={RESET_PASSWORD} element={<ResetPassword />} />
							<Route path={DASHBOARD} element={<PrivateRoute><Dashboard title={title} /></PrivateRoute>}>
								<Route index element={<DashboardHome handleSetTitle={handleSetTitle} />} />
								<Route path={PREFERENCES} element={<Preferences handleSetTitle={handleSetTitle} />} />
								<Route path={PROFILE} element={<Profile handleSetTitle={handleSetTitle} />} />
								
								<Route path={ARTICLES} element={<Articles handleSetTitle={handleSetTitle} />} />
								<Route path={CREATE_ARTICLE} element={<CreateArticle handleSetTitle={handleSetTitle} />} />
								<Route path={`${EDIT_ARTICLE}/:id`} element={<EditArticle handleSetTitle={handleSetTitle} />} />
								
								<Route path={ADD_BROADSHEET_DATA} element={<AddBroadsheetData handleSetTitle={handleSetTitle} />} />
								
								<Route path={ENTRANCE_RESULTS} element={<EntranceResults handleSetTitle={handleSetTitle} />} />
								<Route path={ADD_ENTRANCE_RESULT} element={<AddEntranceResult handleSetTitle={handleSetTitle} />} />
								<Route path={EDIT_ENTRANCE_RESULT} element={<EditEntranceResult handleSetTitle={handleSetTitle} />} />
								<Route path={PROSPECTUS} element={<Prospectus handleSetTitle={handleSetTitle} />} />
								<Route path={ADMISSION_LETTER} element={<AdmissionLetter handleSetTitle={handleSetTitle} />} />
								<Route path={RESULT_PINS} element={<ResultPins handleSetTitle={handleSetTitle} />} />
								
								<Route path={ADD_STAFF} element={<AddStaff handleSetTitle={handleSetTitle} />} />
								<Route path={STAFF} element={<Staff handleSetTitle={handleSetTitle} />} />
								<Route path={VIEW_STAFF} element={<ViewStaff handleSetTitle={handleSetTitle} />} />
								<Route path={ADD_SUBJECT} element={<AddSubject handleSetTitle={handleSetTitle} />} />
								<Route path={MANAGE_SUBJECTS} element={<Subjects handleSetTitle={handleSetTitle} />} />
								<Route path={SUBJECTS_ANALYSIS} element={<SubjectsAnalysis handleSetTitle={handleSetTitle} />} />
								<Route path={SUBJECTS_ANALYSIS_CHART} element={<SubjectAnalysisChart handleSetTitle={handleSetTitle} />} />
								<Route path={`${MANAGE_SUBJECTS}/:id`} element={<EditSubject handleSetTitle={handleSetTitle} />} />
								
								<Route path={SCORES} element={<Scores handleSetTitle={handleSetTitle} />} />
								<Route path={TERMLY_BROAD_SHEET} element={<TermlyBroadSheet handleSetTitle={handleSetTitle} />} />
								<Route path={ANNUAL_BROAD_SHEET} element={<AnnualBroadSheet handleSetTitle={handleSetTitle} />} />
								
								<Route path={ANNUAL_RESULTS} element={<ViewAnnualResults handleSetTitle={handleSetTitle} />} />
								<Route path={ANNUAL_CLASS_RESULTS} element={<AnnualClassResults handleSetTitle={handleSetTitle} />} />
								<Route path={TERMLY_RESULTS} element={<ViewTermlyResults handleSetTitle={handleSetTitle} />} />
								<Route path={TERMLY_CLASS_RESULTS} element={<TermlyClassResults handleSetTitle={handleSetTitle} />} />
								<Route path={JUNIOR_STUDENT_RESULT} element={<JuniorStudentResult handleSetTitle={handleSetTitle} />} />
								<Route path={SENIOR_STUDENT_RESULT} element={<SeniorStudentResult handleSetTitle={handleSetTitle} />} />
								<Route path={`${RESULTS}/:id`} element={<ViewStudentResults handleSetTitle={handleSetTitle} />} />
								<Route path={RATING_TEMPLATES} element={<RatingTemplates handleSetTitle={handleSetTitle} />} />
								<Route path={ANNUAL_RESULT_COMMENTS} element={<AnnualResultComments handleSetTitle={handleSetTitle} />} />
								<Route path={TERMLY_RESULT_COMMENTS} element={<TermlyResultComments handleSetTitle={handleSetTitle} />} />
								
								<Route path={TRANSCRIPTS} element={<Transcripts handleSetTitle={handleSetTitle} />} />
								<Route path={`${TRANSCRIPTS}/:id`} element={<Transcript handleSetTitle={handleSetTitle} />} />
								
								<Route path={ADD_STUDENT} element={<AddStudent handleSetTitle={handleSetTitle} />} />
								<Route path={STUDENTS} element={<Students handleSetTitle={handleSetTitle} />} />
								<Route path={VIEW_STUDENT} element={<Student handleSetTitle={handleSetTitle} />} />
								<Route path={PROMOTE_STUDENTS} element={<PromoteStudents handleSetTitle={handleSetTitle} />} />
								<Route path={ANNUAL_PERFORMANCE_ANALYSIS} element={<AnnualPerformanceAnalysis handleSetTitle={handleSetTitle} />} />
								<Route path={TERMLY_PERFORMANCE_ANALYSIS} element={<TermlyPerformanceAnalysis handleSetTitle={handleSetTitle} />} />
							</Route>
							<Route path="*" element={<p></p>} />
						</Routes>
					</Suspense>
				</LocalizationProvider>
			</ThemeProvider>
		</PersistGate>
	);
}

export default App;
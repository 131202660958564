import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { batch } from 'react-redux';
import { ResetStoreAction, SetCurrentUserAction, SetUserMessageAction } from './types';
import { ActionTypes } from './types';
import axios, { AxiosError } from 'axios';

import {  User } from 'reducers/auth';
import { LoginData } from 'utils/validation/auth/login';
import handleError from 'utils/handleError';
import setAuthToken from 'utils/setAuthToken';

import { DASHBOARD, LOGIN } from 'routes';
import { ResetData } from 'utils/validation/auth/resetPassword';
import { ContactData } from 'utils/validation/auth/contact';

const API = '/api/auth';
let err;

export interface ChangePasswordPayload {
    msg?: string;
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
};

export const getCurrentUser = () => async (dispatch: Dispatch) => {
    try {
        const res = await axios.get(`${API}`);
        const user: User = res.data.data;
        return dispatch<SetCurrentUserAction>({
            type: ActionTypes.SET_CURRENT_USER,
            payload: user
        });
    } catch (error) {
        setAuthToken('');
    }
};

export const login = (data: LoginData, navigate: NavigateFunction) => async (dispatch: Dispatch) => {
    try {
        const res = await axios.post(`${API}/login`, data);
        const user: User = res.data.data.user;
        setAuthToken(res.data.data.token);
        dispatch<SetCurrentUserAction>({
            type: ActionTypes.SET_CURRENT_USER,
            payload: user
        });
        return navigate(DASHBOARD);
    } catch (error) {
        err = error as AxiosError;
        return handleError(err, dispatch);
    }
};

export const register = (data: User) => async (dispatch: Dispatch) => {
    try {

        const res = await axios.post(`${API}/register`, data);
        return dispatch<SetUserMessageAction>({
            type: ActionTypes.SET_USER_MSG,
            payload: res.data.data.msg
        });
    } catch (error) {
        err = error as AxiosError;
        return handleError(err, dispatch);
    }
};

export const forgotPassword = (email: string) => async (dispatch: Dispatch) => {
    try {
        const res = await axios.post(`${API}/forgotPassword`, { email });
        return dispatch<SetUserMessageAction>({
            type: ActionTypes.SET_USER_MSG,
            payload: res.data.msg
        });
    } catch (error) {
        err = error as AxiosError;
        return handleError(err, dispatch);
    }
};

export const updateProfile = (data: User) => async (dispatch: Dispatch) => {
    try {
        const res = await axios.patch(`${API}/updateUser/${data._id}`, data);
        const user: User = res.data.data;
        return batch(() => {
            dispatch<SetUserMessageAction>({
                type: ActionTypes.SET_USER_MSG,
                payload: res.data.msg
            });
            dispatch<SetCurrentUserAction>({
                type: ActionTypes.SET_CURRENT_USER,
                payload: user
            });
        });
    } catch (error) {
        err = error as AxiosError;
        return handleError(err, dispatch);
    }
};

export const changePassword = (data: ChangePasswordPayload) => async (dispatch: Dispatch) => {
    try {
        const res = await axios.patch(`${API}/changePassword`, data);
        setAuthToken(res.data.data.token);
        return dispatch<SetUserMessageAction>({
            type: ActionTypes.SET_USER_MSG,
            payload: res.data.data.msg
        });
    } catch (error) {
        err = error as AxiosError;
        return handleError(err, dispatch);
    }
};

export const resetPassword = (data: ResetData, token: string) => async (dispatch: Dispatch) => {
    try {
        const res = await axios.patch(`${API}/resetPassword/${token}`, data);
        return dispatch<SetUserMessageAction>({
            type: ActionTypes.SET_USER_MSG,
            payload: res.data.msg
        });
    } catch (error) {
        err = error as AxiosError;
        return handleError(err, dispatch);
    }
};

export const contact = (data: ContactData) => async (dispatch: Dispatch) => {
    try {
        const res = await axios.post(`${API}/contact`, data);
        return dispatch<SetUserMessageAction>({
            type: ActionTypes.SET_USER_MSG,
            payload: res.data.msg
        });
    } catch (error) {
        err = error as AxiosError;
        return handleError(err, dispatch);
    }
};

export const logout = (navigate: NavigateFunction, msg: string) => async (dispatch: Dispatch) => {
    setAuthToken('');
    dispatch<ResetStoreAction>({ type: ActionTypes.RESET_STORE });
    navigate(LOGIN, { state: { msg } });
};
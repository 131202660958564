import { ArticleData } from 'utils/validation/articles/create';

export enum ActionTypes {
    SET_ARTICLE = 'SET_ARTICLE',
    SET_ARTICLES = 'SET_ARTICLES',
    DELETED_ARTICLE = 'DELETED_ARTICLE',
    EDITED_ARTICLE = 'EDITED_ARTICLE',
    SET_ARTICLE_MSG = 'SET_ARTICLE_MSG',
}

// Actions Start
export interface SetArticleAction {
    type: ActionTypes.SET_ARTICLE;
    payload: ArticleData | {};
}

export interface SetArticlesAction {
    type: ActionTypes.SET_ARTICLES;
    payload: ArticleData[] | [];
}

export interface DeletedArticleAction {
    type: ActionTypes.DELETED_ARTICLE;
    payload: string;
}

export interface EditedArticle {
    type: ActionTypes.EDITED_ARTICLE;
    payload: ArticleData;
}

export interface SetArticleMessageAction {
    type: ActionTypes.SET_ARTICLE_MSG,
    payload: string | null
}
// Actions End

export type Action = SetArticleAction | SetArticlesAction | DeletedArticleAction | EditedArticle | SetArticleMessageAction;
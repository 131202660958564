import { FC, useRef } from 'react';
import { 
    Backdrop,
    Typography,
    Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import img from '../../assets/img/loader.svg';
import { COLORS } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',

        '& h5': {
            color: COLORS.offWhite,
            fontWeight: 700,
            textTransform: 'uppercase'
        }
    },
}));

interface Classes {
    backdrop: string;
}

interface Props {
    text?: string
}

const Spinner: FC<Props> = ({ text }: Props) => {
    const classes: Classes = useStyles();
    
    const spinner = useRef<HTMLDivElement>(null);

    return (
        <div>
            <Backdrop ref={spinner} className={classes.backdrop} open={true}>
                <img src={img} alt="Spinner" />
                <Typography variant="h5">
                    {text}
                </Typography>
            </Backdrop>
        </div>
    );
};

Spinner.defaultProps = {
    text: 'One Moment . . .'
};

export default Spinner;
import { Error, StudentClass } from 'utils/constants';
import { User } from 'reducers/auth';

export interface PastClass {
    session: string;
    pastClass: StudentClass;
}

export enum ActionTypes {
    SET_CURRENT_USER = 'SET_CURRENT_USER',
    SET_USER_MSG = 'SET_USER_MSG',
    GET_ERRORS = 'GET_ERRORS',
    RESET_STORE = 'RESET_STORE',
}

// Actions Start
export interface SetCurrentUserAction {
    type: ActionTypes.SET_CURRENT_USER;
    payload: User | {};
}

export interface SetUserMessageAction {
    type: ActionTypes.SET_USER_MSG;
    payload: string | null;
}

export interface SetErrorsAction {
    type: ActionTypes.GET_ERRORS,
    payload: Error | {}
}

export interface ResetStoreAction {
    type: ActionTypes.RESET_STORE
}
// Actions End

export type Action = SetCurrentUserAction | SetUserMessageAction | SetErrorsAction;
import { StudentClass, Term, Terms } from 'utils/constants';
import { Score } from 'reducers/scores';

export interface ScorePayload {
    id?: string;
    firstCA?: string;
    secondCA?: string;
    thirdCA?: string;
    fourthCA?: string;
    total?: string;
    exam?: string;
    grade?: string;
    remark?: string;
    session?: string;
    term?: Term;
    selectedClass?: StudentClass;
    student?: string;
    subject?: string;
    msg?: string;
    searchText?: string;
}

export enum ActionTypes {
    SET_SCORE = 'SET_SCORE',
    SET_SCORES = 'SET_SCORES',
    ADDED_SCORE = 'ADDED_SCORE',
    UPDATE_SCORE = 'UPDATE_SCORE',
    SET_SCORES_MSG = 'SET_SCORES_MSG',
    SET_JUNIOR_SCORES = 'SET_JUNIOR_SCORES',
    SET_SENIOR_SCORES = 'SET_SENIOR_SCORES',
    SET_ALL_SCORES = 'SET_ALL_SCORES',
    DELETE_SCORE = 'DELETE_SCORE',
    SET_TRANSCRIPT = 'SET_TRANSCRIPT',
    SET_ANNUAL_BROADSHEET_DATA = 'SET_ANNUAL_BROADSHEET_DATA'
}

export interface AnnualBroadSheetData {
    [key: string]: { // key is studentId
        [key: string]: { //key is subjectId
            [Terms.FIRST_TERM]?: Score;
            [Terms.SECOND_TERM]?: Score;
            [Terms.THIRD_TERM]?: Score;
        }
    }
}

export interface Transcript {
    [key: string]: {
        [key: string]: {
            [key: string]: number;
        }
    }
}

// Actions Start
export interface SetScoresAction {
    type: ActionTypes.SET_SCORES;
    payload: Score[] | [];
}

export interface SetScoreAction {
    type: ActionTypes.SET_SCORE;
    payload: Score
}

export interface SetScoresMessageAction {
    type: ActionTypes.SET_SCORES_MSG;
    payload: string | null;
}

export interface AddedScoreAction {
    type: ActionTypes.ADDED_SCORE;
    payload: Score;
}

export interface UpdateScoreAction {
    type: ActionTypes.UPDATE_SCORE;
    payload: Score;
}

export interface DeleteScoreAction {
    type: ActionTypes.DELETE_SCORE;
    payload: string;
}

export interface SetTranscriptAction {
    type: ActionTypes.SET_TRANSCRIPT;
    payload: Transcript;
}

export interface SetAnnualBroadSheetDataAction {
    type: ActionTypes.SET_ANNUAL_BROADSHEET_DATA;
    payload: AnnualBroadSheetData | {};
}
// Actions End

export type Action = SetScoreAction | SetScoresAction | SetScoresMessageAction | AddedScoreAction | DeleteScoreAction | UpdateScoreAction | SetTranscriptAction | SetAnnualBroadSheetDataAction;
import { Action, ActionTypes } from 'actions/images/types';

const initialState: string[] = [];

export const imagesReducer = (state: string[] = initialState, action: Action) => {
    switch (action.type) {
        case ActionTypes.SET_IMAGE:
            return [...state, action.payload];

        case ActionTypes.CLEAR_IMAGES:
            return [];

        default:
            return state;
    }
};
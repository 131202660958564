import {  User } from 'reducers/auth';

export enum ActionTypes {
    SET_STUDENTS = 'SET_STUDENTS',
    SET_STUDENT = 'SET_STUDENT',
    UPDATE_STUDENT = 'UPDATE_STUDENT',
    MOVED_STUDENT = 'MOVED_STUDENT',
    SET_STUDENT_SUBJECT = 'SET_STUDENT_SUBJECT',
    UNSET_STUDENT_SUBJECT = 'UNSET_STUDENT_SUBJECT',
    SET_STUDENT_MSG = 'SET_STUDENT_MSG',
}

// Actions Start
export interface SetStudentsAction {
    type: ActionTypes.SET_STUDENTS;
    payload: User[] | [];
}

export interface SetStudentAction {
    type: ActionTypes.SET_STUDENT;
    payload: User
}

export interface SetMovedStudentAction {
    type: ActionTypes.MOVED_STUDENT;
    payload: User;
}

export interface SetStudentMessageAction {
    type: ActionTypes.SET_STUDENT_MSG;
    payload: string | null;
}

export interface UpdateStudentAction {
    type: ActionTypes.UPDATE_STUDENT;
    payload: User;
}
// Actions End

export type Action = SetStudentsAction | SetStudentAction | UpdateStudentAction | SetMovedStudentAction | SetStudentMessageAction;
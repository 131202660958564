import { Subject } from 'reducers/subjects';

export enum ActionTypes {
    SET_SUBJECT = 'SET_SUBJECT',
    SET_SUBJECTS = 'SET_SUBJECTS',
    ADDED_SUBJECT = 'ADDED_SUBJECT',
    UPDATE_SUBJECT = 'UPDATE_SUBJECT',
    SET_SUBJECTS_MSG = 'SET_SUBJECTS_MSG',
    SET_JUNIOR_SUBJECTS = 'SET_JUNIOR_SUBJECTS',
    SET_SENIOR_SUBJECTS = 'SET_SENIOR_SUBJECTS',
    SET_ALL_SUBJECTS = 'SET_ALL_SUBJECTS',
    DELETED_SUBJECT = 'DELETED_SUBJECT',
}

// Actions Start
export interface SetSubjectsAction {
    type: ActionTypes.SET_SUBJECTS;
    payload: Subject[] | [];
}

export interface SetSubjectAction {
    type: ActionTypes.SET_SUBJECT;
    payload: Subject
}

export interface SetSubjectsMessageAction {
    type: ActionTypes.SET_SUBJECTS_MSG;
    payload: string | null;
}

export interface AddedSubjectAction {
    type: ActionTypes.ADDED_SUBJECT;
    payload: Subject;
}

export interface SetJuniorSubjectsAction {
    type: ActionTypes.SET_JUNIOR_SUBJECTS;
}

export interface SetSeniorSubjectsAction {
    type: ActionTypes.SET_SENIOR_SUBJECTS;
}

export interface SetAllSubjectsAction {
    type: ActionTypes.SET_ALL_SUBJECTS;
}

export interface UpdateSubjectAction {
    type: ActionTypes.UPDATE_SUBJECT;
    payload: Subject;
}

export interface DeleteSubjectAction {
    type: ActionTypes.DELETED_SUBJECT;
    payload: string;
}
// Actions End

export type Action = SetSubjectsAction | SetSubjectAction | AddedSubjectAction | UpdateSubjectAction | SetSubjectsMessageAction | DeleteSubjectAction | SetJuniorSubjectsAction | SetSeniorSubjectsAction | SetAllSubjectsAction;
import { Action, ActionTypes } from 'actions/preference/types';
import { Error, Term } from 'utils/constants';

export interface Preference extends Error {
    admissionLetter: string;
    newPins: number;
    usedPins: number;
    prospectus: string;
    session: string;
    sessions: string[];
    term: Term;
    vacation: string;
    resumption: string;
    daysOpened: number;
    numberOfStaff?: number;
    numberOfStudents?: number;
}

export interface PreferenceState extends Preference {
    admissionLetter: string;
    newPins: number;
    usedPins: number;
    prospectus: string;
    session: string;
    sessions: string[];
    term: Term;
    vacation: string;
    resumption: string;
    daysOpened: number;
    numberOfStaff?: number;
    numberOfStudents?: number;
    msg?: string | null;
}

const initialState: Preference = {
    admissionLetter: '',
    newPins: 0,
    usedPins: 0,
    prospectus: '',
    session: '',
    sessions: [],
    term: '' as Term,
    vacation: '',
    resumption: '',
    numberOfStaff: 0,
    numberOfStudents: 0,
    daysOpened: 0,
    msg: null
};

export const preferencesReducer =  (state: Preference = initialState, action: Action) => {

    switch (action.type) {
        case ActionTypes.SET_PREFERENCE:
            return {
                ...state,
                ...action.payload
            };

        case ActionTypes.SET_PROSPECTUS:
            return {
                ...state,
                prospectus: action.payload
            };

        case ActionTypes.SET_ADMISSION_LETTER:
            return {
                ...state,
                admissionLetter: action.payload
            };

        case ActionTypes.SET_PREFERENCE_MSG:
            return {
                ...state,
                msg: action.payload
            };

        default:
            return state;
    }
};
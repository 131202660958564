import { Error } from 'utils/constants';

interface Result {
    firstName: string;
    middleName?: string;
    lastName: string;
}

export interface Pin extends Error {
    _id?: string;
    value: string;
    batchNumber: string;
    expired: boolean;
    usage: number;
    result?: Result;
    createdAt?: Date;
    createdBy: string;
}

export enum ActionTypes {
    SET_PINS = 'SET_PINS',
    SET_PIN_MSG = 'SET_PIN_MSG',
}

// Actions Start
export interface SetPinsAction {
    type: ActionTypes.SET_PINS;
    payload: Pin[];
}

export interface SetPinMessageAction {
    type: ActionTypes.SET_PIN_MSG;
    payload: string | null;
}
// Actions End

export type Action = SetPinsAction | SetPinMessageAction;
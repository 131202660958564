import { Error, ExamSubject, Gender } from 'utils/constants';

export interface EntranceResult extends Error {
    _id?: string;
    remainingAttempts?: number;
    email: string;
    examNumber: string;
    firstName: string;
    middleName: string;
    lastName: string;
    gender: Gender;
    phone?: string,
    address?: string,
    maths: ExamSubject;
    english: ExamSubject;
    catholicDoctrine?: ExamSubject;
    generalPaper?: ExamSubject;
    oralInterview?: ExamSubject;
    session: string;
    total: number;
    average: number;
    remark: string;
    entranceClass: string;
    admitted?: boolean;
    createdAt?: Date;
    createdBy?: string;
    lastUpdatedAt?: Date;
    lastUpdatedBy?: string;
}

export enum ActionTypes {
    SET_ENTRANCE_RESULT = 'SET_ENTRANCE_RESULT',
    SET_ENTRANCE_RESULTS = 'SET_ENTRANCE_RESULTS',
    UPDATE_ENTRANCE_RESULT = 'UPDATE_ENTRANCE_RESULT',
    SET_ENTRANCE_RESULT_MSG = 'SET_ENTRANCE_RESULT_MSG'
}

// Actions Start
export interface SetEntranceResultAction {
    type: ActionTypes.SET_ENTRANCE_RESULT;
    payload: EntranceResult;
}

export interface SetEntranceResultsAction {
    type: ActionTypes.SET_ENTRANCE_RESULTS;
    payload: EntranceResult[] | [];
}

export interface SetUpdateEntranceResultAction {
    type: ActionTypes.UPDATE_ENTRANCE_RESULT;
    payload: EntranceResult;
}

export interface SetEntranceResultMessageAction {
    type: ActionTypes.SET_ENTRANCE_RESULT_MSG;
    payload: string | null;
}
// Actions End

export type Action = SetEntranceResultAction | SetEntranceResultsAction | SetUpdateEntranceResultAction | SetEntranceResultMessageAction;
import { Action, ActionTypes } from 'actions/articles/types';
import { ArticleData } from 'utils/validation/articles/create';

export interface ArticleState {
    article: ArticleData;
    articles: ArticleData[];
    msg?: string | null;
}

const initialState: ArticleState = {
    article: {} as ArticleData,
    articles: [],
    msg: null
};

export const articlesReducer = (state: ArticleState = initialState, action: Action) => {
    switch (action.type) {
        case ActionTypes.SET_ARTICLE:
            return {
                ...state,
                article: action.payload
            };

        case ActionTypes.SET_ARTICLES:
            return {
                ...state,
                articles: action.payload
            };

        case ActionTypes.DELETED_ARTICLE:
            return {
                ...state,
                articles: state.articles.filter((article: ArticleData) => article._id !== action.payload)
            };

        case ActionTypes.EDITED_ARTICLE:
            let articleIndex = -1;
            const article = state.articles.find((item: ArticleData, index: number) => {
                if (item._id === action.payload._id) {
                    articleIndex = index;
                    return item;
                }
                return null;
            });
            
            const articles = state.articles;
            articles.splice(articleIndex, 1, { ...action.payload, createdBy: article?.createdBy });

            return {
                ...state,
                articles
            };

        case ActionTypes.SET_ARTICLE_MSG:
            return {
                ...state,
                msg: action.payload
            };

        default:
            return state;
    }
};
import { User } from 'reducers/auth';
import { Action, ActionTypes } from 'actions/students/types';

export interface StudentState {
    students: User[];
    student: User;
    msg: string | null;
}

const initialState: StudentState = {
    student: {} as User,
    students: [],
    msg: null,
};

export const studentsReducer =  (state: StudentState = initialState, action: Action) => {
    let student = {} as User;
    let students: User[];
    let studentIndex: number;
    switch (action.type) {
        case ActionTypes.SET_STUDENT:
            return {
                ...state,
                student: action.payload
            };
        
        case ActionTypes.SET_STUDENTS:
            return {
                ...state,
                students: action.payload
            };

        case ActionTypes.UPDATE_STUDENT:
            student = action.payload;
            students = [...state.students];
            studentIndex = students.findIndex((item: User) => item._id === student._id);
            students.splice(studentIndex, 1, student);

            return {
                ...state,
                student,
                students
            };

        case ActionTypes.MOVED_STUDENT:
            student = action.payload;
            students = [...state.students];
            studentIndex = students.findIndex((item: User) => item._id === student._id);
            students.splice(studentIndex, 1);

            return {
                ...state,
                student,
                students
            };

        case ActionTypes.SET_STUDENT_MSG:
            return {
                ...state,
                msg: action.payload
            };

        default:
            return state;
    }
};
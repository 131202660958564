import { combineReducers } from 'redux';

import { ActionTypes } from 'actions/auth/types';
import { authReducer, AuthState } from './auth';
import { preferencesReducer, PreferenceState } from './preference';
import { pinsReducer, PinState } from './pins';
import { resultsReducer, ResultState } from './results';
import { scoresReducer, ScoreState } from './scores';
import { staffReducer, StaffState } from './staff';
import { studentsReducer, StudentState } from './students';
import { subjectsReducer, SubjectsState } from './subjects';
import { Error, errorsReducer } from './errors';
import { EntranceResultState, entranceResultReducer } from './entranceResults';
import { imagesReducer } from './images';
import { articlesReducer, ArticleState } from './articles';

export interface AppState {
    auth: AuthState;
    articles: ArticleState,
    entranceResults: EntranceResultState;
    images: string[],
    preference: PreferenceState;
    pins: PinState;
    results: ResultState;
    scores: ScoreState;
    staff: StaffState;
    students: StudentState;
    subjects: SubjectsState;
    errors: Error;
}

interface Action {
    type: ActionTypes;
    payload?: any;
}

const appReducer = combineReducers<AppState>({
    auth: authReducer,
    // @ts-ignore
    articles: articlesReducer,
    entranceResults: entranceResultReducer,
    images: imagesReducer,
    preference: preferencesReducer,
    pins: pinsReducer,
    results: resultsReducer,
    scores: scoresReducer,
    staff: staffReducer,
    students: studentsReducer,
    subjects: subjectsReducer,
    errors: errorsReducer
});

const rootReducer = (state: AppState | undefined, action: Action) => {
    if (action.type === ActionTypes.RESET_STORE) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
import { Action, ActionTypes, PerformanceAnalysis } from 'actions/results/types';
import { Error, ResultType, StudentClass, Term } from 'utils/constants';
import { User } from './auth';

export interface Result extends Error {
    ['S/N']?: number;
    _id?: string;
    type: ResultType;
    class: StudentClass;
    comment?: string;
    average: number;
    total: number;
    conduct?: string | number;
    daysPresent?: number | null;
    attendance?: number | null;
    punctuality?: number | null;
    games?: number | null;
    assignments?: number | null;
    schoolActivities?: number | null;
    neatness?: number | null;
    politeness?: number | null;
    honesty?: number | null;
    selfControl?: number | null;
    relationshipWithOthers?: number | null;
    leadershipRoles?: number | null;
    creativity?: number | null;
    initiative?: number | null;
    communication?: number | null;
    responsibility?: number | null;
    numberInClass: number;
    daysOpened?: number;
    numberOfSubjects: number;
    resumption: string;
    vacation: string;
    position?: string;
    term: Term;
    session: string;
    student: string | User;
    createdAt?: Date;
    createdBy: string;
    lastEditedAt?: Date;
    lastEditedBy?: string;
}

export interface ResultState {
    result: Result;
    annualResults: Result[];
    results: Result[];
    studentResults: Result[];
    performanceAnalysis: PerformanceAnalysis;
    msg: string | null;
}

const initialState: ResultState = {
    result: {} as Result,
    annualResults: [],
    results: [],
    studentResults: [],
    performanceAnalysis: {
        results: [],
        juniorResults: [],
        seniorResults: [],
        overallBest: {} as Result
    },
    msg: null,
};

export const resultsReducer =  (state: ResultState = initialState, action: Action) => {
    switch (action.type) {
        
        case ActionTypes.SET_RESULT:
            return {
                ...state,
                result: action.payload
            };

        case ActionTypes.SET_RESULTS:
            return {
                ...state,
                results: action.payload
            };

        case ActionTypes.SET_STUDENT_RESULTS:
            return {
                ...state,
                studentResults: action.payload
            };

        case ActionTypes.SET_RESULTS_MSG:
            return {
                ...state,
                msg: action.payload
            };

        case ActionTypes.PROMOTE_STUDENT:
            return {
                ...state,
                results: state.results.filter((result: any) => result.student._id !== action.payload)
            };

        case ActionTypes.SET_PERFORMANCE_ANALYSIS:
            return {
                ...state,
                performanceAnalysis: action.payload
            };

        case ActionTypes.CLEAR_PERFORMANCE_ANALYSIS:
            return {
                ...state,
                performanceAnalysis: {
                    results: [],
                    juniorResults: [],
                    seniorResults: [],
                    overallBest: {} as Result
                }
            };

        default:
            return state;
    }
};
import { User } from './auth';
import { Action, ActionTypes, SubjectTaught } from 'actions/staff/types';

export interface StaffState {
    staffs: User[];
    staff: User;
    msg: string | null;
}

const initialState: StaffState = {
    staff: {} as User,
    staffs: [],
    msg: null,
};

export const staffReducer = (state: StaffState = initialState, action: Action) => {
    let staff = {} as User;
    let staffs: User[];
    let staffIndex: number;
    switch (action.type) {
        case ActionTypes.SET_STAFF:
            return {
                ...state,
                staff: action.payload
            };
        
        case ActionTypes.SET_STAFFS:
            return {
                ...state,
                staffs: action.payload
            };

        case ActionTypes.UPDATE_STAFF:
            staff = action.payload;
            staffs = [...state.staffs];
            staffIndex = staffs.findIndex((item: User) => item._id === staff._id);
            staffs.splice(staffIndex, 1, staff);

            return {
                ...state,
                staff,
                staffs
            };

        case ActionTypes.SET_STAFF_SUBJECT:
            staffs = [...state.staffs];
            staffIndex = staffs.findIndex((item: User) => item._id === action.payload.staffId);
            staff = staffs[staffIndex];
            staff.subjectsTaught = action.payload.subjects;
            staffs.splice(staffIndex, 1, staff);
            return {
                ...state,
                staff: { ...state.staff, subjectsTaught: action.payload.subjects },
                staffs
            };

        case ActionTypes.UNSET_STAFF_SUBJECT:
            staffs = [...state.staffs];
            staffIndex = staffs.findIndex((item: User) => item._id === action.payload.staffId);
            staff = staffs[staffIndex];
            staff.subjectsTaught?.splice(staff.subjectsTaught.findIndex((item: SubjectTaught) => item._id === action.payload._id), 1);
            staffs.splice(staffIndex, 1, staff);
            return {
                ...state,
                staff,
                staffs
            };

        case ActionTypes.SET_STAFF_MSG:
            return {
                ...state,
                msg: action.payload
            };

        default:
            return state;
    }
};
import { Route } from 'utils/constants';

export const ABOUT = '/about';
export const NEWS = '/news';
export const GALLERY = '/gallery';
export const CONTACT = '/contact';

export const links: Route[] = [
    { text: 'Home', link: '/' },
    { text: 'About', link: ABOUT },
    { text: 'News', link: NEWS },
    { text: 'Gallery', link: GALLERY },
    { text: 'Contact', link: CONTACT }
];

export const LOGIN = '/auth/login';
export const FORGOT_PASSWORD = '/auth/forgotPassword';
export const RESET_PASSWORD = '/auth/resetPassword';
export const RESULT_CHECKER = '/resultChecker';
export const ENTRANCE_RESULT = '/entraceResult';
export const VIEW_ADMISSION_LETTER = '/admissionLetter';
export const VIEW_PROSPECTUS = '/prospectus';

export const DASHBOARD = '/dashboard';
export const PREFERENCES = `${DASHBOARD}/preferences`;
export const PROFILE = `${DASHBOARD}/profile`;

export const ADD_BROADSHEET_DATA = `${DASHBOARD}/addBroadsheetData`;

export const ENTRANCE_RESULTS = `${DASHBOARD}/entranceResults`;
export const ADD_ENTRANCE_RESULT = `${DASHBOARD}/addEntranceResult`;
export const EDIT_ENTRANCE_RESULT = `${DASHBOARD}/entranceResults/edit`;
export const PROSPECTUS = `${DASHBOARD}/prospectus`;
export const ADMISSION_LETTER = `${DASHBOARD}/admissionLetter`;
export const RESULT_PINS = `${DASHBOARD}/pins`;

export const ARTICLES = `${DASHBOARD}/articles`;
export const CREATE_ARTICLE = `${ARTICLES}/create`;
export const EDIT_ARTICLE = `${ARTICLES}/edit`;

export const ADD_STAFF = `${DASHBOARD}/addStaff`;
export const STAFF = `${DASHBOARD}/staff`;
export const VIEW_STAFF = `${DASHBOARD}/viewStaff`;
export const MANAGE_SUBJECTS = `${DASHBOARD}/subjects`;
export const ADD_SUBJECT = `${DASHBOARD}/subjects/add`;
export const SUBJECTS_ANALYSIS = `${DASHBOARD}/subjectsAnalysis`;
export const SUBJECTS_ANALYSIS_CHART = `${DASHBOARD}/subjectsAnalysisChart`;

export const STUDENTS = `${DASHBOARD}/students`;
export const ADD_STUDENT = `${STUDENTS}/add`;
export const VIEW_STUDENT = `${STUDENTS}/view`;
export const PROMOTE_STUDENTS = `${STUDENTS}/promote`;
export const TERMLY_PERFORMANCE_ANALYSIS = `${DASHBOARD}/termlyPerformanceAnalysis`;
export const ANNUAL_PERFORMANCE_ANALYSIS = `${DASHBOARD}/annualPerformanceAnalysis`;

export const SCORES = `${STUDENTS}/scores`;
export const IMPORT_SCORES = `${SCORES}/#!import`;
export const TERMLY_BROAD_SHEET = `${DASHBOARD}/termlyBroadSheet`;
export const ANNUAL_BROAD_SHEET = `${DASHBOARD}/annualBroadSheet`;

export const RESULTS = `${STUDENTS}/results`;
export const ANNUAL_RESULTS = `${RESULTS}/annual`;
export const ANNUAL_CLASS_RESULTS = `${ANNUAL_RESULTS}/classResults`;
export const TERMLY_RESULTS = `${RESULTS}/termly`;
export const TERMLY_CLASS_RESULTS = `${TERMLY_RESULTS}/classResults`;
export const JUNIOR_STUDENT_RESULT = `${RESULTS}/juniorStudentResult`;
export const SENIOR_STUDENT_RESULT = `${RESULTS}/seniorStudentResult`;
export const GENERATE_RESULTS = `${RESULTS}/#!generate`;
export const CORRECT_RESULTS = `${RESULTS}/#!correct`;
export const RATING_TEMPLATES = `${RESULTS}/ratingTemplates`;
export const ANNUAL_RESULT_COMMENTS = `${RESULTS}/annualComments`;
export const TERMLY_RESULT_COMMENTS = `${RESULTS}/termlyComments`;

export const TRANSCRIPTS = `${DASHBOARD}/transcripts`;
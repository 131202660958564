import { Result } from 'reducers/results';

export interface PerformanceAnalysis {
    results: Result[];
    juniorResults: Result[];
    seniorResults: Result[];
    overallBest: Result;
}

export enum ActionTypes {
    SET_RESULT = 'SET_RESULT',
    SET_RESULTS = 'SET_RESULTS',
    ADDED_RESULT = 'ADDED_RESULT',
    UPDATE_RESULT = 'UPDATE_RESULT',
    SET_RESULTS_MSG = 'SET_RESULTS_MSG',
    SET_JUNIOR_RESULTS = 'SET_JUNIOR_RESULTS',
    SET_SENIOR_RESULTS = 'SET_SENIOR_RESULTS',
    SET_ALL_RESULTS = 'SET_ALL_RESULTS',
    SET_STUDENT_RESULTS = 'SET_STUDENT_RESULTS',
    PROMOTE_STUDENT = 'PROMOTE_STUDENT',
    CLEAR_PERFORMANCE_ANALYSIS = 'CLEAR_PERFORMANCE_ANALYSIS',
    SET_PERFORMANCE_ANALYSIS = 'SET_PERFORMANCE_ANALYSIS',
    DELETE_RESULT = 'DELETE_RESULT',
}

// Actions Start
export interface SetResultsAction {
    type: ActionTypes.SET_RESULTS;
    payload: Result[] | [];
}

export interface SetResultAction {
    type: ActionTypes.SET_RESULT;
    payload: Result
}

export interface SetResultsMessageAction {
    type: ActionTypes.SET_RESULTS_MSG;
    payload: string | null;
}

export interface SetStudentResultsAction {
    type: ActionTypes.SET_STUDENT_RESULTS;
    payload: Result[] | [];
}

export interface PromoteStudentAction {
    type: ActionTypes.PROMOTE_STUDENT;
    payload: string;
}

export interface SetPerformanceAnalysisAction {
    type: ActionTypes.SET_PERFORMANCE_ANALYSIS;
    payload: PerformanceAnalysis;
}

export interface ClearPerformanceAnalysisAction {
    type: ActionTypes.CLEAR_PERFORMANCE_ANALYSIS;
}
// Actions End

export type Action = SetResultAction | SetResultsAction | SetStudentResultsAction | SetResultsMessageAction | PromoteStudentAction | SetPerformanceAnalysisAction | ClearPerformanceAnalysisAction;
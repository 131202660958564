import { useState, FC, forwardRef, useImperativeHandle } from 'react';
import { 
    Box,
	Button,
    Fade,
    Modal,
	Typography 
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { CheckboxMarkedCircle } from 'mdi-material-ui';

import { COLORS } from '../../utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    container: {
        backgroundColor: COLORS.lightTeal,
        borderRadius: theme.shape.borderRadius,
        display: 'grid',
        gridTemplateColumns: '1fr',
        alignItems: 'center',
        width: '35vw',
        minHeight: '15vw',
        padding: theme.spacing(2, 5),

        '& p': {
            textAlign: 'center',
        },

        [theme.breakpoints.down('md')]: {
            height: '50vw',
            width: '50vw',
        },
        
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(5, 2),
            height: '40vh',
            width: '90vw'
        }
    },

    item: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center'
    },

    icon: {
        justifySelf: 'center !important',
        color: theme.palette.primary.main,
        fontSize: `${theme.spacing(5)} !important`
    },

    button: {
        justifySelf: 'center',
        width: 'initial !important'
    }
}));

interface Classes {
    icon: string;
    item: string;
    container: string;
    modal: string;
    button: string;
}

interface Props {
    dismissAction?: () => void;
    ref: any;
}

const SuccessModal: FC<Props> = forwardRef<HTMLDivElement, Props>((props: Props, ref: any) => {
	const classes: Classes = useStyles();

    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');

    const { dismissAction } = props;

    const closeModal = (): void => {
        setOpen(false);
        if (dismissAction) {
            dismissAction();
        }
    };

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setOpen(true);
        },

        closeModal: () => {
            setOpen(false);
        },

        setText: (text: string) => {
            setText(text);
        }
    }));
    
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        closeModal();
    };

	return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="success-modal"
            aria-describedby="modal-modal-description"
            className={classes.modal}
            disableEscapeKeyDown
            // open={open}
            // closeAfterTransition
            // BackdropComponent={Backdrop}
            // BackdropProps={{
            //     timeout: 500,
            // }}
        >
            <Fade in={open}>
                <Box component="div" className={classes.container}>
                    <CheckboxMarkedCircle className={classes.icon} />
                    <Typography variant="subtitle1" component="p">
                        {text}
                    </Typography>
                    <Button onClick={closeModal} color="primary" variant="contained" className={classes.button}>Okay</Button>
                </Box>
            </Fade>
        </Modal>
	);
});

export default SuccessModal;
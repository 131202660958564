import { Action, ActionTypes } from 'actions/auth/types';
import { Error, Gender, PastClass, StudentClass, UserRole, UserStatus } from 'utils/constants';
import { SubjectTaught } from 'actions/staff/types';

export interface User {
    _id?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    email?: string;
    admissionNumber?: string;
    password?: string;
    confirmPassword?: string;
    address?: string,
    phone?: string,
    disabled?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    lastSeen?: Date;
    avatar?: string;
    avatarKey?: string;
    subjectsTaught?: SubjectTaught[];
    currentClass?: StudentClass;
    pastClasses?: PastClass[];
    classOwned?: StudentClass; 
    gender?: Gender;
    role?: UserRole;
    status?: UserStatus;
    resetPasswordToken?: string;
    resetPasswordExpire?: Date;
    msg?: string | null;
}

export interface AuthState extends Error {
    isAuthenticated?: boolean;
    user: User;
    msg?: string | null;
}

const initialState: AuthState = {
    user: {} as User,
    isAuthenticated: false,
    msg: null
};

export const authReducer = (state: AuthState = initialState, action: Action) => {
    switch (action.type) {
        case ActionTypes.SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload
            };

        case ActionTypes.SET_USER_MSG:
            return {
                ...state,
                msg: action.payload
            };

        default:
            return state;
    }
};
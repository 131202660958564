import { Error } from 'utils/constants';
import { Action, ActionTypes } from 'actions/subjects/types';
import { ClassCategories } from 'utils/constants';

export interface Subject extends Error {
    _id?: string;
    subjectClass: string;
    category?: string;
    title: string;
    slug: string;
    createdAt?: Date;
    createdBy?: string;
    lastEditedAt?: Date;
    lastEditedBy?: string;
}

export interface SubjectsState {
    subjects: Subject[];
    subject: Subject;
    juniorSubjects: Subject[],
    seniorSubjects: Subject[],
    msg: string | null;
}

const initialState: SubjectsState = {
    subject: {} as Subject,
    subjects: [],
    juniorSubjects: [],
    seniorSubjects: [],
    msg: null,
};

export const subjectsReducer =  (state: SubjectsState = initialState, action: Action) => {
    let subject = {} as Subject;
    let subjects: Subject[];
    let subjectIndex: number;
    switch (action.type) {
        case ActionTypes.ADDED_SUBJECT:
            return {
                ...state,
                subjects: [ action.payload, ...state.subjects ],
                juniorSubjects: state.juniorSubjects.length > 0 ? [ action.payload, ...state.juniorSubjects ] : state.juniorSubjects,
                seniorSubjects: state.seniorSubjects.length > 0 ? [ action.payload, ...state.seniorSubjects ] : state.seniorSubjects
            };
 
        case ActionTypes.SET_SUBJECT:
            return {
                ...state,
                subject: action.payload
            };
        
        case ActionTypes.SET_SUBJECTS:
            return {
                ...state,
                subjects: action.payload
            };

        case ActionTypes.UPDATE_SUBJECT:
            subject = action.payload;
            subjects = [...state.subjects];
            subjectIndex = subjects.findIndex((item: Subject) => item._id === subject._id);
            subjects.splice(subjectIndex, 1, subject);

            return {
                ...state,
                subject,
                subjects
            };

        case ActionTypes.SET_JUNIOR_SUBJECTS:
            return {
                ...state,
                juniorSubjects: state.subjects.filter(subject => subject.subjectClass === ClassCategories.JUNIOR_CLASS),
                seniorSubjects: [],
            };

        case ActionTypes.SET_SENIOR_SUBJECTS:
            return {
                ...state,
                seniorSubjects: state.subjects.filter(subject => subject.subjectClass === ClassCategories.SENIOR_CLASS),
                juniorSubjects: []
            };

        case ActionTypes.SET_ALL_SUBJECTS:
            return {
                ...state,
                juniorSubjects: [],
                seniorSubjects: []
            };

        case ActionTypes.DELETED_SUBJECT:
            return {
                ...state,
                subjects: state.subjects.filter(subject => subject._id !== action.payload)
            };

        case ActionTypes.SET_SUBJECTS_MSG:
            return {
                ...state,
                msg: action.payload
            };

        default:
            return state;
    }
};